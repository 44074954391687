import {CashierMandatoryField, CashierMandatoryFieldsOverrides, PaymentStoreProps} from '../../types/app.types';
import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {CheckoutService} from '../services/CheckoutService';
import {PaymentsWidgetConfiguration} from '@wix/cashier-payments-widget/src/types/PaymentsWidgetConfiguration';
import {NavigationService} from '../services/NavigationService';
import {MemberService} from '../services/MemberService';
import {COUNTRIES_WITH_VAT} from '../../components/Checkout/constants';

export type PaymentStoreConfig = {
  flowAPI: ControllerFlowAPI;
  checkoutService: CheckoutService;
  navigationService: NavigationService;
  memberService: MemberService;
  updateComponent: () => void;
};

export class PaymentStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly checkoutService: CheckoutService;
  private readonly navigationService: NavigationService;
  private readonly memberService: MemberService;
  private readonly updateComponent: () => void;
  private cashierMandatoryFields: CashierMandatoryFieldsOverrides;

  constructor({flowAPI, checkoutService, navigationService, memberService, updateComponent}: PaymentStoreConfig) {
    this.flowAPI = flowAPI;
    this.checkoutService = checkoutService;
    this.navigationService = navigationService;
    this.memberService = memberService;
    this.updateComponent = updateComponent;
    this.cashierMandatoryFields = {};
  }

  private readonly setCashierMandatoryFields = (cashierMandatoryFields: CashierMandatoryFieldsOverrides): void => {
    this.cashierMandatoryFields = cashierMandatoryFields;
    /* istanbul ignore next */
    this.checkoutService.checkout && this.updateComponent();
  };

  private readonly isShippingValidForBilling = (): boolean => {
    const shippingContactAndAddress = this.checkoutService.checkout?.shippingDestination;
    let isValid = true;

    if (
      shippingContactAndAddress?.address?.country &&
      COUNTRIES_WITH_VAT.includes(shippingContactAndAddress.address.country)
    ) {
      return false;
    }

    const cashierMandatoryFieldsArr = Object.keys(this.cashierMandatoryFields).filter(
      (field) => this.cashierMandatoryFields[field as CashierMandatoryField] === true
    );

    cashierMandatoryFieldsArr.forEach((field) => {
      switch (field) {
        case CashierMandatoryField.address:
          isValid = Boolean(shippingContactAndAddress?.address.addressLine);
          break;
        // istanbul ignore next
        case CashierMandatoryField.city:
          isValid = Boolean(shippingContactAndAddress?.address.city);
          break;
        // istanbul ignore next
        case CashierMandatoryField.countryCode:
          isValid = Boolean(shippingContactAndAddress?.address.country);
          break;
        case CashierMandatoryField.street:
          isValid = Boolean(shippingContactAndAddress?.address.streetAddress?.name);
          break;
        case CashierMandatoryField.houseNumber:
          isValid = Boolean(shippingContactAndAddress?.address.streetAddress?.number);
          break;
        case CashierMandatoryField.state:
          isValid = Boolean(shippingContactAndAddress?.address.subdivision);
          break;
        case CashierMandatoryField.zipCode:
          isValid = Boolean(shippingContactAndAddress?.address.postalCode);
          break;
        case CashierMandatoryField.phone:
          isValid = Boolean(shippingContactAndAddress?.contact.phone);
          break;
        // istanbul ignore next
        default:
          isValid = false;
          break;
      }
    });

    return isValid;
  };

  public toProps(): PaymentStoreProps {
    const configuration: PaymentsWidgetConfiguration = {
      locale: this.flowAPI.translations.config.language,
      appId: this.flowAPI.controllerConfig.appParams.appDefinitionId,
      appInstanceId: this.flowAPI.controllerConfig.appParams.instanceId,
      amount: `${this.checkoutService.checkout.payNowTotalAfterGiftCard.amount}`,
      currency: this.checkoutService.checkout.currency!,
      msid: this.flowAPI.controllerConfig.platformAPIs.bi?.metaSiteId as string,
      appInstance: this.flowAPI.controllerConfig.appParams.instance,
      visitorId: this.flowAPI.controllerConfig.platformAPIs.bi?.visitorId,
      siteOwnerId: this.flowAPI.controllerConfig.platformAPIs.bi?.ownerId,
      lang: this.flowAPI.translations.config.language,
      contactId: this.checkoutService.checkout.buyerInfo.contactId,
      viewMode: 'Site',
      country: 'US', // TODO
      isPrimaryLanguage: true, // TODO!
      isSignedInUser: this.memberService.isMember(),
    };

    return {
      cashierWidgetProps: {
        configuration,
        locale: this.flowAPI.translations.config.language,
        theme: 'default',
        shouldApplySiteStyles: false,
        externalSubmitButton: true,
        walletPaymentInNewTab: false,
        allowManualPayment: true,
        isSaveCCEnabled: this.navigationService.isMyWalletAppInstalled,
        allowRecurringPaymentOnly: this.checkoutService.checkout.hasSubscriptionItems,
      },
      cashierConfiguration: configuration,
      cashierMandatoryFields: this.cashierMandatoryFields,
      setCashierMandatoryFields: this.setCashierMandatoryFields,
      isShippingValidForBilling: this.isShippingValidForBilling(),
    };
  }
}
