import {ItemType, ItemTypeItemType} from '@wix/ambassador-ecom-v1-checkout/types';
import {ItemTypeFragment} from '../../gql/graphql';

export class ItemTypeModel {
  public preset?: ItemTypePreset;
  public custom?: string;
  constructor(itemType?: ItemType | ItemTypeFragment) {
    this.preset = itemType?.preset ? toItemTypePreset(itemType.preset as ItemTypeItemType) : undefined;
    this.custom = itemType?.custom ?? undefined;
  }
}

export enum ItemTypePreset {
  PHYSICAL = 'PHYSICAL',
  DIGITAL = 'DIGITAL',
  UNRECOGNISED = 'UNRECOGNISED',
  GIFT_CARD = 'GIFT_CARD',
  SERVICE = 'SERVICE',
}

const toItemTypePreset = (itemType: ItemTypeItemType) => {
  switch (itemType) {
    case ItemTypeItemType.PHYSICAL:
      return ItemTypePreset.PHYSICAL;
    case ItemTypeItemType.DIGITAL:
      return ItemTypePreset.DIGITAL;
    case ItemTypeItemType.SERVICE:
      return ItemTypePreset.SERVICE;
    case ItemTypeItemType.UNRECOGNISED:
      return ItemTypePreset.UNRECOGNISED;
    case ItemTypeItemType.GIFT_CARD:
      return ItemTypePreset.GIFT_CARD;
  }
};
