import {SubscriptionOptionInfo, SubscriptionFrequency} from '@wix/ambassador-ecom-v1-checkout/types';
import {SubscriptionOptionInfoFragment} from '../../gql/graphql';

export class SubscriptionModel {
  public name: string;
  public isAutoRenewal: boolean;
  public frequency: SubscriptionFrequency;
  public interval: number;
  public billingCycles: number;

  constructor({title, subscriptionSettings}: SubscriptionOptionInfo | SubscriptionOptionInfoFragment) {
    this.name = title?.translated ?? title?.original ?? '';
    this.isAutoRenewal = Boolean(subscriptionSettings?.autoRenewal);
    this.frequency = (subscriptionSettings?.frequency as SubscriptionFrequency) ?? SubscriptionFrequency.UNDEFINED;
    this.interval = subscriptionSettings?.interval ?? 1;
    this.billingCycles = subscriptionSettings?.billingCycles ?? 0;
  }
}
