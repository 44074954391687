import {LineItem, ItemAvailabilityInfo, ItemAvailabilityStatus} from '@wix/ambassador-ecom-v1-checkout/types';
import {ItemMediaModel} from './ItemMedia.model';
import {ItemPricesModel} from './ItemPrices.model';
import {ItemTypeModel} from './ItemType.model';
import {LineItemOptionModel} from './LineItemOption.model';
import {SubscriptionModel} from './Subscription.model';
import {ItemAvailabilityInfoFragment, LineItemFragment} from '../../gql/graphql';

export class LineItemModel {
  public id: string;
  public catalogAppId: string;
  public productName: string;
  public media?: ItemMediaModel;
  public prices: ItemPricesModel;
  public options: LineItemOptionModel[];
  public quantity: number;
  public subscription?: SubscriptionModel;
  public itemType: ItemTypeModel;
  public sku?: string;

  constructor({
    id,
    media,
    productName,
    lineItemPrice,
    price,
    fullPrice,
    priceBeforeDiscounts,
    catalogReference,
    quantity,
    subscriptionOptionInfo,
    itemType,
    availability,
    physicalProperties,
    descriptionLines,
  }: LineItem | LineItemFragment) {
    this.id = id!;
    this.catalogAppId = catalogReference!.appId!;
    this.itemType = new ItemTypeModel(itemType!);
    this.productName = productName?.translated ?? productName?.original ?? '';
    this.media = media && (media.id || media.url) ? new ItemMediaModel(media, this.productName) : undefined;
    this.prices = new ItemPricesModel({
      lineItemPrice: lineItemPrice ?? /* istanbul ignore next */ undefined,
      price: price ?? /* istanbul ignore next */ undefined,
      fullPrice: fullPrice ?? /* istanbul ignore next */ undefined,
      priceBeforeDiscounts: priceBeforeDiscounts ?? /* istanbul ignore next */ undefined,
    });
    this.options = descriptionLines
      ? LineItemOptionModel.convertToOptionsList(descriptionLines)
      : /* istanbul ignore next */ [];
    this.quantity = calculateQuantity(availability!, Number(quantity));
    this.subscription = subscriptionOptionInfo ? new SubscriptionModel(subscriptionOptionInfo) : undefined;
    this.sku = physicalProperties?.sku ?? undefined;
  }
}

const calculateQuantity = (
  availability: ItemAvailabilityInfo | ItemAvailabilityInfoFragment,
  quantity: number
): number => {
  if (availability.status === ItemAvailabilityStatus.NOT_AVAILABLE) {
    return 0;
  }
  if (availability.status === ItemAvailabilityStatus.PARTIALLY_AVAILABLE) {
    return availability.quantityAvailable!;
  }
  return quantity;
};
