import {Image} from '@wix/ambassador-ecom-v1-checkout/types';
import {ImageFragment} from '../../gql/graphql';

export class ItemMediaModel {
  public altText: string | null;
  public height?: number;
  public width?: number;
  public src: string;

  constructor({height, width, id, url, altText}: Image | ImageFragment, productName: string) {
    this.height = height ?? /* istanbul ignore next */ undefined;
    this.width = width ?? /* istanbul ignore next */ undefined;
    this.src = id ?? url!;
    this.altText = altText || productName;
  }
}
