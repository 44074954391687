import {DeliveryLogistics} from '@wix/ambassador-ecom-v1-checkout/types';
import {AddressModel} from './Address.model';
import {DeliveryLogisticsFragment} from '../../gql/graphql';

export class PickupInfoModel {
  public address?: AddressModel;
  public deliveryTime?: string;
  public instructions?: string;
  constructor(deliveryLogistics?: DeliveryLogistics | DeliveryLogisticsFragment) {
    this.address = deliveryLogistics?.pickupDetails?.address
      ? new AddressModel(deliveryLogistics.pickupDetails.address)
      : undefined;
    this.deliveryTime = deliveryLogistics?.deliveryTime ?? undefined;
    this.instructions = deliveryLogistics?.instructions ?? undefined;
  }
}
