import {ShippingOption} from '@wix/ambassador-ecom-v1-checkout/types';
import {ShippingOptionFragment} from '../../gql/graphql';

export class ScheduledDeliveryOptionModel {
  public code: string;
  public deliveryTime: string;
  constructor(option: ShippingOption | ShippingOptionFragment) {
    this.code = option.code!;
    this.deliveryTime = option.logistics?.deliveryTime ?? '';
  }
}
