import {MultiCurrencyPrice} from '@wix/ambassador-ecom-v1-checkout/types';
import {MultiCurrencyPriceFragment} from '../../gql/graphql';

export class PriceModel {
  public amount: number;
  public formattedAmount: string;
  constructor(price?: MultiCurrencyPrice | MultiCurrencyPriceFragment | null) {
    this.amount = Number(price?.amount) || 0;
    this.formattedAmount = price?.formattedAmount ?? '';
  }
}
