import {CustomField} from '@wix/ambassador-ecom-v1-checkout/types';
import {CustomFieldFragment} from '../../gql/graphql';

export class CustomFieldModel {
  public title: string;
  public value: string;
  constructor(customField: CustomField | CustomFieldFragment) {
    this.title = customField.translatedTitle ?? customField.title!;
    this.value = customField.value as string;
  }
}
