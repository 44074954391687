import {ShippingOption} from '@wix/ambassador-ecom-v1-checkout/types';
import {PriceModel} from './Price.model';
import {PickupInfoModel} from './PickupInfo.model';
import {ScheduledDeliveryOptionModel} from './ScheduledDeliveryOption.model';
import {ShippingOptionFragment} from '../../gql/graphql';

export class ShippingOptionModel {
  public code: string;
  public title: string;
  public pickupInfo?: PickupInfoModel;
  public deliveryTime: string;
  public price: PriceModel;
  public scheduledDeliveryOptions?: ScheduledDeliveryOptionModel[];

  constructor(
    shippingOption: ShippingOption | ShippingOptionFragment,
    scheduledDeliveryOptions?: (ShippingOption | ShippingOptionFragment)[]
  ) {
    this.code = shippingOption.code!;
    this.title = shippingOption.title!;
    this.pickupInfo = shippingOption?.logistics?.pickupDetails
      ? new PickupInfoModel(shippingOption?.logistics)
      : undefined;
    this.deliveryTime = shippingOption.logistics?.deliveryTime ?? '';
    this.price = new PriceModel(shippingOption.cost?.price);
    this.scheduledDeliveryOptions = scheduledDeliveryOptions?.map((option) => new ScheduledDeliveryOptionModel(option));
  }
}
